import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { MenuLink } from '../MenuLink';
import { Text } from '../Text';
import { useStateValue } from '../../state';
import { menuPathItems } from '../../common/menuPathItems';

const actions = {
  changeLocation: `changeLocation`,
};

function onLinkClick(dispatch, path) {
  return () => {
    dispatch({
      type: actions.changeLocation,
      newLocationState: { current: path },
    });
  };
}

interface IDesktopTopMenu {
  className?: string;
  location: string;
}

const StyledDesktopTopMenu = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  max-width: ${rem(`1200px`)};
  .desktop-top-menu__logo {
    position: absolute;
    top: ${rem(`6px`)};
    left: ${rem(`-64px`)};
    width: ${rem(`48px`)};
    height: ${rem(`48px`)};
  }
  .desktop-top-menu__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;
  }
  .desktop-top-menu__firm-name {
    color: ${({ theme }) => theme.colors.white_1};
    margin: 0;
    padding: 0;
    flex: 0 1 auto;
    :hover {
      color: ${({ theme }) => theme.colors.pink_1};
    }
  }
  .desktop-top-menu__items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 auto;
    /*
    // USE THIS IF YOU WANT GAP
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 12px;
    place-content: center;
    width: 100%;
    */
    /* margin-left: ${rem(`-48px`)}; */
  }
  .desktop-top-menu__item {
    flex: 0 1 auto;
    padding: 0 ${rem(`24px`)};
    cursor: pointer;
    &:last-child {
      padding-right: 0;
    }
  }
`;

export const DesktopTopMenu = ({
  className,
  location = `/`,
}: IDesktopTopMenu) => {
  const [{ locationState }, dispatch] = useStateValue();
  const { logoSmall } = useStaticQuery(graphql`
    query LogoSmallQuery {
      logoSmall: contentfulAsset(
        contentful_id: { eq: "3d6RRjX4QkuDsKlbP6Mejy" }
      ) {
        svg {
          dataURI
        }
      }
    }
  `);
  const logoImageSmall = logoSmall?.svg?.dataURI;

  useEffect(() => {
    dispatch({
      type: actions.changeLocation,
      newLocationState: { current: location },
    });
  }, []);
  const onLinkClickBound = onLinkClick.bind(``, dispatch);
  const { current } = locationState;
  const currentPath = current.split(`/`)[1] || ``;
  return (
    <StyledDesktopTopMenu className={`${className}`}>
      <img
        alt="menu logo"
        src={logoImageSmall}
        className="desktop-top-menu__logo"
      />
      <section className="desktop-top-menu__section">
        <AnchorLink to="/#top">
          <Text type="title4" className="desktop-top-menu__firm-name">
            Domusnetwork
          </Text>
        </AnchorLink>

        <div className="desktop-top-menu__items">
          {menuPathItems.map(
            ({ key, link, desktopLink, displayName, icon }) => (
              <MenuLink
                key={key}
                className="desktop-top-menu__item"
                onClick={onLinkClickBound(desktopLink)}
                icon={icon}
                active={currentPath === link || current === desktopLink}
                disabled={false}
                leftLineIndicator={false}
                higlighted={displayName === `Contact`}
                to={desktopLink}
              >
                {displayName}
              </MenuLink>
            ),
          )}
        </div>
      </section>
    </StyledDesktopTopMenu>
  );
};
