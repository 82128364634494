import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { faChartNetwork } from '@fortawesome/pro-light-svg-icons';
import { Arrow } from '../Arrow';
import { MenuLink } from '../MenuLink';
import { menuPathItems } from '../../common/menuPathItems';
import { useStateValue } from '../../state';

const { useEffect } = React;

const actions = {
  changeLocation: `changeLocation`,
  collapseLeftMenu: `collapseLeftMenu`,
  toggleLeftMenu: `toggleLeftMenu`,
};

function onLinkClick(dispatch, path) {
  return () => {
    dispatch({
      type: actions.changeLocation,
      newLocationState: { current: path },
    });
    dispatch({
      type: actions.toggleLeftMenu,
      newLayoutState: { isLeftOpen: false },
    });
  };
}

interface ISideMenu {
  className?: string;
  location: string;
  removeCollapse?: boolean;
}

interface IStyledSideMenu {
  collapsed: any;
}

const StyledSideMenu = styled.div<IStyledSideMenu>`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-start;
  height: initial;
  min-height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue_1};
  padding-top: ${rem(`4px`)};
  transition: width 300ms ease-in-out, min-width 300ms ease-in-out;
  ${({ theme, collapsed }) => theme.breakpoints.phablet`
    width: ${collapsed ? rem(`44px`) : rem(`200px`)};
  `}
  .side-menu__arrow {
    display: none;
    position: absolute;
    bottom: 0;
    ${({ theme }) => theme.breakpoints.phablet`
      display: flex;
    `}
  }
  .side-menu__items {
    opacity: 0;
    visibility: hidden;
    flex: 1 0;
    width: 100%;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    ${({ theme }) => theme.breakpoints.phablet`
      opacity: 1;
      visibility: visible;
    `}
    &.parent--open {
      opacity: 1;
      visibility: visible;
      transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    }
  }
  .side-menu__item {
    flex: 1 0 100%;
    :not(:last-child) {
      margin-bottom: 4px;
    }
    cursor: pointer;
  }
`;

export const SideMenu = ({
  className,
  location = `/`,
  removeCollapse = false,
}: ISideMenu) => {
  const [{ layoutState, locationState }, dispatch] = useStateValue();
  let { isMenuCollapsed } = layoutState;
  const { isLeftOpen } = layoutState;
  isMenuCollapsed = removeCollapse ? false : isMenuCollapsed;
  useEffect(() => {
    dispatch({
      type: actions.changeLocation,
      newLocationState: { current: location },
    });
  }, []);
  const onLinkClickBound = onLinkClick.bind(``, dispatch);
  const { current } = locationState;
  const currentPath = current.split(`/`)[1] || ``;
  return (
    <StyledSideMenu className={`${className}`} collapsed={isMenuCollapsed}>
      <div className={`${isLeftOpen ? `parent--open` : ``} side-menu__items`}>
        {[
          {
            key: `home`,
            link: ``,
            desktopLink: `#home`,
            displayName: `Home`,
            icon: faChartNetwork,
          },
          ...menuPathItems,
        ].map(({ key, link, displayName, icon }) => (
          <MenuLink
            key={key}
            className="side-menu__item"
            onClick={onLinkClickBound(link)}
            icon={icon}
            active={currentPath === link}
            disabled={false}
            to={link}
            collapsed={isMenuCollapsed}
          >
            {displayName}
          </MenuLink>
        ))}
      </div>
      {removeCollapse ? null : (
        <Arrow
          onClick={() =>
            dispatch({
              type: actions.collapseLeftMenu,
              newLayoutState: { isMenuCollapsed: !isMenuCollapsed },
            })
          }
          className="side-menu__arrow"
          toLeft={!isMenuCollapsed}
          toRight={isMenuCollapsed}
        />
      )}
    </StyledSideMenu>
  );
};
