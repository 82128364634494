import styled from 'styled-components';

export const StyledArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ theme }) => theme.rhythmSizing(4)}rem;
  background-color: transparent;
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.white_1};
    transition: color 300ms ease-in-out;
  }
  ${({ theme }) => theme.breakpoints.desktop`
    &:hover svg {
      color: ${theme.colors.pink_1};
    }
  `}
  .arrow__icon {
    color: ${({ theme }) => theme.colors.white_1};
    &.arrow--disabled {
      color: ${({ theme }) => theme.colors.gray_3_65};
    }
  }
`;
