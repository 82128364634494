import React, { useContext } from 'react';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import { Drawer } from '../Drawer';
import { useStateValue } from '../../state';
import { isUnderBreakpoint } from './utils';

export interface IAside {
  children: any;
  disableBackground?: boolean;
}

const actions = {
  toggleRightMenu: `toggleRightMenu`,
};

function drawerOnClickHandler(dispatch) {
  return ({ target }) => {
    return target.tagName === `INPUT` ||
      target.tagName === `SPAN` ||
      target.tagName === `BUTTON` ||
      target.classList.contains(`newsletter`)
      ? null
      : dispatch({
          type: actions.toggleRightMenu,
          newLayoutState: { isRightOpen: false },
        });
  };
}

export const Aside = ({ children, disableBackground = true }: IAside) => {
  const breakpoints = useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const [{ layoutState }, dispatch] = useStateValue();
  const { isRightOpen } = layoutState;
  const isUnderTablet = isUnderBreakpoint(breakpoint, [
    `watch`,
    `phone`,
    `phablet`,
  ]);
  return (
    <Drawer
      noBackground={disableBackground}
      onClick={isUnderTablet ? drawerOnClickHandler(dispatch) : null}
      disabled={!isUnderTablet}
      isOpen={isRightOpen}
      direction="right"
      topOffset="46px"
      width="250px"
      className="layout__right-drawer"
    >
      <div className="layout__right-drawer-container">{children}</div>
    </Drawer>
  );
};
