import React, { useEffect, useRef, useContext, ReactNode } from 'react';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import styled from 'styled-components';
import { rem } from 'polished';
import logoAscii from '../../../meta/logoAscii';
import GlobalStyles from '../../styles/globalStyles';
import { SideMenu } from '../SideMenu/index';
import { MobileTopMenu } from '../MobileTopMenu';
import { Drawer } from '../Drawer';
import { Spinner } from '../Spinner';
import { useStateValue } from '../../state';
import {
  isDesktopBreakpoint,
  isMobileBreakpoint,
  isTabletBreakpoint,
} from './utils';
import { DesktopExtra } from './DesktopExtra';

if (typeof window !== `undefined`) {
  // eslint-disable-next-line global-require
  require(`smooth-scroll`)(`a[href*="#"]`);
}

const actions = {
  toggleLeftMenu: `toggleLeftMenu`,
  toggleRightMenu: `toggleRightMenu`,
  collapseRightAside: `collapseRightAside`,
  collapseLeftMenu: `collapseLeftMenu`,
  toggleSpinner: `toggleSpinner`,
  setLayoutElementRef: `setLayoutElementRef`,
  setFirstRun: `setFirstRun`,
};

export interface ILayout {
  children: any;
  location: any;
  coverImage?: any;
  hasRightContent?: boolean;
  topSvg?: string;
  bottomSvg?: string;
  variant?: number;
  desktopHeading: ReactNode;
  desktopBody?: ReactNode;
  desktopCta: ReactNode;
}

interface IStyledLayout {
  isMenuCollapsed: any;
}

export const StyledLayout = styled.div<IStyledLayout>`
  display: block;
  position: relative;
  position: relative;
  height: 100%;
  ${({ theme }) => theme.breakpoints.phablet`
    height: initial;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  `}
  .layout__top-bar {
    flex: 1 0 100%;
    z-index: 2;
  }
  .layout__menu {
    z-index: 3;
    ${({ theme }) => theme.breakpoints.phablet`
      position: fixed;
    `}
  }
  .layout__main {
    display: flex;
    flex: 1 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: ${rem(`46px`)};
    min-height: 100vh;
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.blue_1},
      ${({ theme }) => theme.colors.magenta_2}
    );
    background-origin: content-box;
    color: ${({ theme }) => theme.colors.white_1};
    transition: padding-left 300ms ease-in-out, padding-right 500ms ease-in-out;
    ${({ theme, isMenuCollapsed }) => theme.breakpoints.phablet`
      padding-top: 0;
      padding-left: ${isMenuCollapsed ? rem(`44px`) : rem(`200px`)};
    `}
  }
  .layout__right-drawer {
    z-index: 3;
    &-container {
      padding: 0 ${rem(`16px`)};
      overflow-y: scroll;
      height: calc(100% - 36px);
      ${({ theme }) => theme.breakpoints.phablet`
        overflow-y: auto;
        height: auto;
      `}
    }
  }
  .layout__aside {
    position: fixed;
    z-index: 2;
    right: 0;
    top: 0;
    margin: auto;
  }
`;

export const Layout = ({
  children,
  location,
  coverImage,
  hasRightContent,
  topSvg,
  bottomSvg,
  variant = 1,
  desktopHeading,
  desktopBody,
  desktopCta,
}: ILayout) => {
  const layoutRef = useRef(null);
  const breakpoints = useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const isMobile = isMobileBreakpoint(breakpoint);
  const isTablet = isTabletBreakpoint(breakpoint);
  const isDesktop = isDesktopBreakpoint(breakpoint);
  const [{ layoutState, locationState }, dispatch] = useStateValue();
  const {
    isRightOpen,
    isLeftOpen,
    isMenuCollapsed,
    isSpinnerActive,
    isFirstRun,
  } = layoutState;
  const { current } = locationState;
  const header =
    current === `/` || current === ``
      ? `Home`
      : current.charAt(1).toUpperCase() + current.slice(2);
  useEffect(() => {
    if (isFirstRun) {
      // eslint-disable-next-line no-console
      console.log(`%c${logoAscii}`, `color:${`#FF55B6`}; font-size: 9px;`);
    }
    dispatch({
      type: actions.setFirstRun,
      newLayoutState: { isFirstRun: false },
    });
    dispatch({
      type: actions.toggleSpinner,
      newLayoutState: { isSpinnerActive: false },
    });
    dispatch({
      type: actions.setLayoutElementRef,
      newLayoutState: { elementRef: layoutRef.current },
    });
  }, []);

  return (
    <>
      <GlobalStyles />
      <Spinner shouldShow={isSpinnerActive} />
      {isMobile && (
        <MobileTopMenu
          className="layout__top-bar"
          isRightOpen={isRightOpen}
          isLeftOpen={isLeftOpen}
          shouldShowRight={hasRightContent}
          title={header}
          onClickExpand={() => {
            dispatch({
              type: actions.toggleRightMenu,
              newLayoutState: { isRightOpen: !isRightOpen },
            });
          }}
          onClickBars={() => {
            dispatch({
              type: actions.toggleLeftMenu,
              newLayoutState: { isLeftOpen: !isLeftOpen },
            });
          }}
        />
      )}
      <StyledLayout ref={layoutRef} isMenuCollapsed={isMenuCollapsed}>
        {isMobile && (
          <Drawer
            noBackground
            onClick={() => {
              dispatch({
                type: actions.toggleLeftMenu,
                newLayoutState: { isLeftOpen: false },
              });
            }}
            disabled={false}
            isOpen={isLeftOpen}
            direction="left"
            width="200px"
            topOffset="46px"
          >
            <SideMenu
              removeCollapse
              className="layout__menu"
              location={location.pathname.replace(/\/$/, ``)}
            />
          </Drawer>
        )}
        {isTablet && (
          <Drawer
            noBackground
            onClick={() => null}
            disabled
            isOpen={isLeftOpen}
            direction="left"
            width={null}
            topOffset="46px"
          >
            <SideMenu
              removeCollapse={false}
              className="layout__menu"
              location={location.pathname.replace(/\/$/, ``)}
            />
          </Drawer>
        )}
        {(isMobile || isTablet) && (
          <section className="layout__main">{children}</section>
        )}
        {isDesktop && (
          <DesktopExtra
            topSvg={topSvg}
            bottomSvg={bottomSvg}
            variant={variant}
            coverImage={coverImage}
            heading={desktopHeading}
            cta={desktopCta}
            location={location.pathname.replace(/\/$/, ``)}
          >
            {desktopBody}
          </DesktopExtra>
        )}
      </StyledLayout>
    </>
  );
};
