import React, { useContext } from 'react';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import styled from 'styled-components';
import { rem } from 'polished';
import { Footer } from '../Footer';
import { ScrollIndicator } from '../ScrollIndicator';
import { useStateValue } from '../../state';
import {
  isDesktopBreakpoint,
  isMobileBreakpoint,
  isTabletBreakpoint,
} from './utils';

interface IMain {
  children: any;
  background?: string;
  disableVertPadding?: boolean;
  showScrrollBackground?: boolean;
}

interface IStyledMainContent {
  disableVertPadding: any;
  background: any;
}

interface IStyledMainFooter {
  isMenuCollapsed: any;
}

const StyledMainContent = styled.section<IStyledMainContent>`
  flex: 0 1 auto;
  z-index: 2;
  width: 100%;
  max-width: 100%;
  padding: ${({ disableVertPadding }) => (disableVertPadding ? 0 : rem(`24px`))}
    ${rem(`16px`)} 0;
  background-color: ${({ theme, background }) =>
    background || theme.colors.white_1};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: ${({ theme }) => theme.rhythmSizing(16)}rem;
  ${({ theme }) => theme.breakpoints.tablet`
    min-height: ${rem(`920px`)};
  `}
`;

const StyledMainFooter = styled.section<IStyledMainFooter>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: ${({ theme }) => theme.rhythmSizing(16)}rem;
  z-index: 2;
  display: flex;
  align-items: center;
  transition: padding-left 300ms ease-in-out;
  ${({ theme, isMenuCollapsed }) => theme.breakpoints.phablet`
    padding-left: ${isMenuCollapsed ? rem(`44px`) : rem(`200px`)};
  `}
`;

export const Main = ({
  children,
  background,
  disableVertPadding = true,
  showScrrollBackground = true,
}: IMain) => {
  const breakpoints = useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const isMobile = isMobileBreakpoint(breakpoint);
  const isTablet = isTabletBreakpoint(breakpoint);
  const isDesktop = isDesktopBreakpoint(breakpoint);
  const [{ layoutState }] = useStateValue();
  const { isMenuCollapsed } = layoutState;
  return (
    <>
      <StyledMainContent
        disableVertPadding={disableVertPadding}
        background={background}
      >
        {children}
        {isMobile ? (
          <StyledMainFooter isMenuCollapsed={isMenuCollapsed}>
            <Footer />
          </StyledMainFooter>
        ) : null}
        {isDesktop && showScrrollBackground ? (
          <ScrollIndicator isMenuCollapsed={isMenuCollapsed} />
        ) : null}
      </StyledMainContent>
      {isTablet ? (
        <StyledMainFooter isMenuCollapsed={isMenuCollapsed}>
          <Footer />
        </StyledMainFooter>
      ) : null}
    </>
  );
};
