import {
  faLink,
  faRocket,
  faInfo,
  faEnvelope,
  faConciergeBell,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';

interface IMenuPathItems {
  key: string;
  link: string;
  desktopLink: string;
  displayName: string;
  icon: IconDefinition;
}

export const menuPathItems: IMenuPathItems[] = [
  {
    key: `services`,
    link: `services`,
    desktopLink: `#services`,
    displayName: `Services`,
    icon: faConciergeBell,
  },
  {
    key: `processes`,
    link: `processes`,
    desktopLink: `#processes`,
    displayName: `Processes`,
    icon: faLink,
  },
  {
    key: `projects`,
    link: `projects`,
    desktopLink: `#projects`,
    displayName: `Projects`,
    icon: faRocket,
  },
  // { key: `pricing`, link: `pricing`, displayName: `Pricing`, icon: faBarcode },
  {
    key: `about`,
    link: `about`,
    desktopLink: `#about`,
    displayName: `About`,
    icon: faInfo,
  },
  // { key: `blog`, link: `blog`, displayName: `Blog`, icon: faBlog },
  {
    key: `contact`,
    link: `contact`,
    desktopLink: `#contact`,
    displayName: `Contact`,
    icon: faEnvelope,
  },
];
