import React from 'react';
import { StyledSpinner } from './styles';

export interface ISpinner {
  className?: string;
  shouldShow?: boolean;
}

export const Spinner = ({ className, shouldShow }: ISpinner) => {
  return (
    <>
      {shouldShow ? (
        <StyledSpinner className={`${className}`}>
          <div className="loading outer">
            <div className="loading inner" />
          </div>
        </StyledSpinner>
      ) : null}
    </>
  );
};
