import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Text } from '../Text/index';

interface IMenuLink {
  children: any;
  icon: IconProp;
  disabled?: boolean;
  active?: boolean;
  className?: string;
  collapsed?: boolean;
  higlighted?: boolean;
  to: string;
  onClick?: () => void;
  leftLineIndicator: boolean;
}

interface IStyledMenuLink {
  disabled: any;
  active: any;
  collapsed: any;
  higlighted: any;
  theme: any;
  leftLineIndicator: boolean;
}

const StyledMenuLink = styled.div.attrs(
  ({ active, disabled, theme }: IStyledMenuLink) => {
    if (active) {
      return {
        color: theme.colors.pink_1,
      };
    }
    return disabled
      ? { color: theme.colors.white_1_45 }
      : { color: theme.colors.gray_2 };
  },
)<IStyledMenuLink>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${({ theme }) => theme.rhythmSizing(3)}rem;
  position: relative;
  > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${({ active, theme, leftLineIndicator }) => {
    return (
      leftLineIndicator &&
      css`
        border-left-style: solid;
        border-left-width: ${rem(`6px`)};
        border-left-color: ${active ? theme.colors.pink_1 : `transparent`};
        transition: border-left-color 300ms ease-in-out;
      `
    );
  }}
  :hover {
    .menu-link__text,
    .menu-link__icon {
    color: ${({ theme, higlighted, active }) =>
      !active && higlighted ? theme.colors.white_1 : theme.colors.pink_1};
    }
  }

    ${({ active, higlighted, theme }) => {
      return (
        higlighted &&
        !active &&
        css`
          a {
            background-image: linear-gradient(
              to right,
              ${theme.colors.yellow_3},
              ${theme.colors.yellow_1}
            );
            padding: 0 20px 0 12px;
            border-radius: ${rem(`6px`)};
            transition: background-image 300ms ease-in-out;
            cursor: pointer;
            position: relative;
            z-index: 1;
            &::before {
              position: absolute;
              content: '';
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: ${rem(`6px`)};
              background-image: linear-gradient(
                to left,
                ${theme.colors.pink_2},
                ${theme.colors.pink_1}
              );
              z-index: -1;
              transition: opacity 0.3s linear, background-image 0.3s linear;
              opacity: 0;
            }
            &:hover::before {
                opacity: 1;
            }
            .menu-link__icon,
            .menu-link__text {
              color: ${theme.colors.blue_1}};
            }
            &:hover .menu-link__icon,
            &:hover .menu-link__text {
              color: ${theme.colors.white_1}};
            }
          }
        `
      );
    }}
  }

  button {
    pointer-events: all;
    display: block;
    overflow: hidden;
    width: 100%;
    flex: 1 0;
    display: flex;
    align-items: center;
  }
  .menu-link__icon {
    margin: 0 ${rem(`8px`)};
    width: ${rem(`16px`)};
    min-width: ${rem(`16px`)};
    color: ${({ color }) => color};
    transition: color 300ms ease-in-out;
  }
  .menu-link__text {
    text-overflow: ellipsis;
    overflow: hidden;
    visibility: ${({ collapsed }) => (collapsed ? `hidden` : `visible`)};
    opacity: ${({ collapsed }) => (collapsed ? `0` : `1`)};
    color: ${({ color }) => color};
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out,
      color 300ms ease-in-out;
  }
  .menu-link__bubble {
    display: flex;
    align-items: center;
    position: absolute;
    left: ${rem(`50px`)};
    top: 0;
    height: ${({ theme }) => theme.rhythmSizing(3)}rem;
    background-color: ${({ theme }) => theme.colors.blue_1};
    border-radius: 3px;
    padding: 0 ${rem(`16px`)};
    ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -6px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid ${({ theme }) => theme.colors.blue_1};
    }
  }
  &.collapsed .menu-link__bubble {
    visibility: hidden;
    opacity: 0;
    color: ${({ color }) => color};
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out,
      color 200ms ease-in-out;
  }
  &.collapsed:hover .menu-link__bubble {
    visibility: visible;
    opacity: 1;
    color: ${({ color }) => color};
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out,
      color 200ms ease-in-out;
  }
`;

export const MenuLink = ({
  icon,
  disabled,
  active,
  children,
  className,
  onClick,
  collapsed,
  higlighted,
  to,
  leftLineIndicator = true,
}: IMenuLink) => {
  const collapsedClass = collapsed ? `collapsed` : ``;
  return (
    <StyledMenuLink
      onClick={onClick}
      disabled={disabled}
      active={active}
      className={`${collapsedClass} ${className}`}
      collapsed={collapsed}
      higlighted={higlighted}
      leftLineIndicator={leftLineIndicator}
    >
      <>
        <AnchorLink to={`/${to}`}>
          <FontAwesomeIcon className="menu-link__icon" icon={icon} />
          <Text className="menu-link__text" noRhythem type="menu1" tag="span">
            {children}
          </Text>
          {collapsed ? (
            <div className="menu-link__bubble">{children}</div>
          ) : null}
        </AnchorLink>
      </>
    </StyledMenuLink>
  );
};
