import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

interface IDrawer {
  children: any;
  isOpen: boolean;
  disabled: boolean;
  direction: 'left' | 'right';
  width?: string | null;
  topOffset: string | 0;
  onClick: ((e?) => void) | null;
  noBackground?: boolean;
  className?: any;
}

interface IStyledDrawer {
  topOffset: any;
  direction: any;
  width: any;
  noBackground: any;
}

export const StyledDrawer = styled.div<IStyledDrawer>`
  flex: 0 1;
  z-index: 2;
  position: fixed;
  top: ${({ topOffset }) => rem(topOffset)};
  right: ${({ direction }) => (direction === `right` ? 0 : `initial`)};
  left: ${({ direction }) => (direction === `left` ? 0 : `initial`)};
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.blue_1};
  min-width: ${({ width }) => (width ? rem(width) : `initial`)};
  width: ${({ width }) => width};
  transform-origin: top ${({ direction }) => direction};
  transition: width 300ms ease-in-out, min-width 300ms ease-in-out;
  :before {
    content: '';
    transition: background-color 300ms ease-in-out;
    background-color: transparent;
  }
  ${({ theme }) => theme.breakpoints.phablet`
    height: 100%;
    background-color: transparent;
    flex: 0 1 ${({ width }) => (width ? rem(width) : `auto`)};
    position: relative;
    top: initial;
    right: initial;
    left: initial;
    width: auto;
    min-width: ${({ width }) => (width ? rem(width) : `initial`)};
  `}
  &.drawer--open {
    :before {
      width: 100vw;
      position: absolute;
      top: 0;
      right: ${({ direction }) => (direction === `right` ? `100%` : `auto`)};
      left: ${({ direction }) => (direction === `left` ? `100%` : `auto`)};
      bottom: 0;
      margin: auto;
      z-index: -1;
      background-color: ${({ theme }) => theme.colors.blue_1_65};
    }
  }
  &.drawer--closed {
    min-width: 0;
    width: 0;
    overflow: hidden;
    ${({ theme, width }) => theme.breakpoints.phablet`
      min-width: ${width ? rem(width) : `initial`};
      overflow: visible;
    `}
  }
`;

export const Drawer = ({
  children,
  isOpen = true,
  disabled = false,
  direction = `right`,
  width,
  topOffset = 0,
  onClick,
  noBackground = false,
  className = `drawer`,
}: IDrawer) => {
  const isOpenClass = isOpen ? `drawer--open` : `drawer--closed`;
  return (
    <StyledDrawer
      noBackground={noBackground}
      onClick={onClick}
      topOffset={topOffset}
      width={width}
      direction={direction}
      className={`${className} ${!disabled ? isOpenClass : null}`}
    >
      {children}
    </StyledDrawer>
  );
};

export default Drawer;
