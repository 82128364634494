export function isUnderBreakpoint(current, breakpoints) {
  return breakpoints.some((breakpoint) => breakpoint === current);
}

function isInRange(breakpoints) {
  return (current) => {
    return breakpoints.some((breakpoint) => breakpoint === current);
  };
}

export const isMobileBreakpoint = isInRange([`watch`, `phone`]);
export const isTabletBreakpoint = isInRange([`phablet`, `tablet`]);
export const isDesktopBreakpoint = isInRange([`desktop`, `wide`]);
