import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledFooter {
  showBackground: boolean;
  disableBackground: boolean;
  showLogo: any;
}

export const StyledFooter = styled.div<IStyledFooter>`
  display: flex;
  align-items: center;
  justify-content: ${({ showLogo }) => (showLogo ? `flex-start` : `center`)};
  width: 100%;
  padding: 0 ${rem(`32px`)};
  height: ${({ theme }) => theme.rhythmSizing(6)}rem;
  background-image: ${({ theme, disableBackground }) =>
    disableBackground
      ? null
      : `linear-gradient(
    90deg,
    ${theme.colors.blue_1_00},
    ${theme.colors.blue_1_65}
  )`};
  background-image: ${({ theme, showBackground, disableBackground }) =>
    !disableBackground && showBackground
      ? `linear-gradient(
    90deg,
    ${theme.colors.blue_1_00} 5%,
    ${theme.colors.blue_1_65} 15%,
    ${theme.colors.blue_1} 90%
  )`
      : null};
  .footer__image {
    height: ${({ theme }) => theme.rhythmSizing(6)}rem;
    min-width: ${rem(`72px`)};
  }
  .footer__text {
    padding-left: ${rem(`16px`)};
    color: ${({ theme }) => theme.colors.gray_1};
  }
`;
