import styled from 'styled-components';
import { rem } from 'polished';

export const StyledSpinner = styled.div`
  background: radial-gradient(
      circle,
      ${({ theme }) => theme.colors.blue_1} 0%,
      ${({ theme }) => theme.colors.blue_2} 100%
    )
    no-repeat;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    padding: ${rem(`16px`)};
    vertical-align: middle;
    text-align: center;
    background-color: transparent;
    border: ${rem(`3px`)} solid transparent;
    border-top-color: ${({ theme }) => theme.colors.pink_1};
    border-bottom-color: ${({ theme }) => theme.colors.pink_1};
    border-radius: 50%;
  }
  .outer {
    animation: spin 1s infinite;
  }
  .inner {
    animation: spin 1s infinite;
  }
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;
