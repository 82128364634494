import React from 'react';
import { StyledContentHtml, StyledContent } from './styles';

interface IContent {
  className?: string;
  children?: any;
  restrictWidth?: boolean;
  disableTextStyles?: boolean;
  enableFlex?: boolean;
  disableSubtitlePadding?: boolean;
  contrast?: boolean;
  shouldCenter?: boolean;
  style?: any;
  id?: string;
  setMaxWidth?: string;
  noMargin?: boolean;
}

interface IContentHtml {
  className?: string;
  children?: any;
  restrictWidth?: boolean;
}

export const Content = ({
  children,
  className,
  restrictWidth,
  disableTextStyles,
  enableFlex,
  disableSubtitlePadding,
  contrast,
  shouldCenter,
  style,
  id,
  setMaxWidth,
  noMargin = false,
}: IContent) => (
  <StyledContent
    id={id}
    style={style}
    disableSubtitlePadding={disableSubtitlePadding}
    disableTextStyles={disableTextStyles}
    restrictWidth={restrictWidth}
    enableFlex={enableFlex}
    contrast={contrast}
    shouldCenter={shouldCenter}
    className={className}
    setMaxWidth={setMaxWidth}
    noMargin={noMargin}
  >
    {children}
  </StyledContent>
);

export const HTMLContent = ({
  children,
  className,
  restrictWidth,
}: IContentHtml) => (
  <StyledContentHtml
    restrictWidth={restrictWidth}
    className={className}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
