import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faArrowToLeft,
  faArrowToRight,
  faExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { StyledArrow } from './styles';

interface IArrow {
  onClick?: () => void;
  disabled?: boolean;
  size?: SizeProp;
  className?: string;
  left?: boolean;
  right?: boolean;
  up?: boolean;
  down?: boolean;
  toLeft?: boolean;
  toRight?: boolean;
}

function arrows(arrowsMap: object): IconProp {
  const arrowType: string = Object.keys(arrowsMap).filter(
    (key) => arrowsMap[key] === true,
  )[0];
  switch (arrowType) {
    case `left`:
      return faArrowLeft;
    case `right`:
      return faArrowRight;
    case `up`:
      return faArrowUp;
    case `up2`:
      return faArrowUp;
    case `down`:
      return faArrowDown;
    case `toLeft`:
      return faArrowToLeft;
    case `toRight`:
      return faArrowToRight;
    default:
      return faExclamation;
  }
}

export const Arrow = ({
  onClick,
  disabled = false,
  size = `1x`,
  className,
  left = false,
  right = false,
  up = false,
  down = false,
  toLeft = false,
  toRight = false,
}: IArrow) => {
  const disabledclass = disabled ? `arrow--disabled` : ``;
  const arrowsMap = {
    left,
    right,
    up,
    down,
    toLeft,
    toRight,
  };
  return (
    <StyledArrow
      role="button"
      aria-label="collapse menu"
      name="collapse-arrow"
      className={`${disabledclass} ${className}`}
      onClick={!disabled ? onClick : null}
    >
      <FontAwesomeIcon
        size={size}
        className={`arrow__icon ${disabledclass}`}
        icon={arrows(arrowsMap)}
      />
    </StyledArrow>
  );
};
