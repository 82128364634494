import React from 'react';
import { StyledScrollIndicator } from './styles';
import { useStateValue } from '../../state';
import { Arrow } from '../Arrow/index';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useScrollPosition from '../../hooks/useScrollPosition';

export interface IScrollIndicator {
  className?: string;
  isMenuCollapsed: boolean;
}

export const ScrollIndicator = ({
  isMenuCollapsed,
  className,
}: IScrollIndicator) => {
  const { height: windowHeight } = useWindowDimensions();
  const { y: scrollPositionY } = useScrollPosition();
  const [{ layoutState }] = useStateValue();
  const clientHeight =
    layoutState.elementRef && layoutState.elementRef.clientHeight;
  const handleDownClick = () =>
    window.scrollTo({
      top: windowHeight,
      behavior: `smooth`,
    });
  const handleUpClick = () =>
    window.scrollTo({
      top: 0,
      behavior: `smooth`,
    });
  return (
    <StyledScrollIndicator
      isMenuCollapsed={isMenuCollapsed}
      className={className}
    >
      {clientHeight > windowHeight && scrollPositionY <= 0 ? (
        <Arrow
          size="1x"
          className="scrollIndicator__arrow scrollIndicator__arrow--down"
          onClick={handleDownClick}
          down
        />
      ) : null}
      {clientHeight > windowHeight && scrollPositionY > 0 ? (
        <Arrow
          size="1x"
          className="scrollIndicator__arrow scrollIndicator__arrow--up"
          onClick={handleUpClick}
          up
        />
      ) : null}
    </StyledScrollIndicator>
  );
};
