import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StyledFooter } from './styles';
import { Text } from '../Text';

interface IFooter {
  className?: string;
  showLogo?: boolean;
  showBackground?: boolean;
  disableBackground?: boolean;
}

export const Footer = ({
  showLogo = true,
  showBackground,
  disableBackground,
  className,
}: IFooter) => {
  const { logoImg } = useStaticQuery(graphql`
    query LogoQuery {
      logoImg: contentfulAsset(
        contentful_id: { eq: "3d6RRjX4QkuDsKlbP6Mejy" }
      ) {
        svg {
          dataURI
        }
      }
    }
  `);
  const image = logoImg?.svg?.dataURI;

  return (
    <StyledFooter
      showBackground={showBackground}
      disableBackground={disableBackground}
      showLogo={showLogo}
      className={className}
    >
      {showLogo ? (
        <img src={image} className="footer__image" alt="footer logo" />
      ) : null}
      <Text className="footer__text" noRhythem tag="span" type="caption1">
        Copyright © Domusnetwork.io 2024. All Rights Reserved.
      </Text>
    </StyledFooter>
  );
};
