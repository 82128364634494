import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimesHexagon } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { Arrow } from '../Arrow/index';
import { Text } from '../Text';

interface IMobileTopMenu {
  onClickExpand?: () => void;
  onClickBars?: () => void;
  title: string;
  shouldShowRight?: boolean;
  isRightOpen?: boolean;
  isLeftOpen?: boolean;
  className?: string;
}

const StyledMobileTopMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 ${rem(`16px`)};
  position: fixed;
  z-index: 9;
  overflow: hidden;
  height: ${rem(`46px`)};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue_1};
  ${({ theme }) => theme.breakpoints.tablet`
    display: none;
  `}
  .mobile-top-menu__arrow {
    height: 100%;
    flex: 0 1;
    display: flex;
    justify-content: flex-end;
  }
  .mobile-top-menu__text {
    height: 100%;
    flex: 1 0;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.pink_1};
    padding: ${rem(`2px`)} ${rem(`16px`)} 0;
  }
  .mobile-top-menu__hamburger {
    background: transparent;
    color: ${({ theme }) => theme.colors.white_1};
    height: 100%;
    flex: 0 1 ${rem(`18px`)};
    cursor: pointer;
    &.mobile-top-menu__hamburger--disabled {
      color: ${({ theme }) => theme.colors.gray_1};
    }
  }
`;

export const MobileTopMenu = ({
  onClickExpand,
  onClickBars,
  title,
  isRightOpen,
  isLeftOpen,
  className,
  shouldShowRight = false,
}: IMobileTopMenu) => {
  const disabledHamburgerclass = isRightOpen
    ? `mobile-top-menu__hamburger--disabled`
    : ``;
  return (
    <StyledMobileTopMenu className={className}>
      <button
        onClick={isRightOpen ? () => null : onClickBars}
        aria-label="toggle menu"
        name="toggle"
        type="button"
        className={`mobile-top-menu__hamburger ${disabledHamburgerclass}`}
      >
        <FontAwesomeIcon icon={isLeftOpen ? faTimesHexagon : faBars} />
      </button>
      <Text className="mobile-top-menu__text" type="body1" noRhythem tag="h2">
        {title.replace(`/collection`, ``)}
      </Text>
      {shouldShowRight ? (
        <Arrow
          disabled={isLeftOpen}
          onClick={onClickExpand}
          className="mobile-top-menu__arrow"
          toLeft={!isRightOpen}
          toRight={isRightOpen}
        />
      ) : null}
    </StyledMobileTopMenu>
  );
};
