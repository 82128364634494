import React, { ReactNode, useRef } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useActiveOnIntersect } from '@/hooks/useActiveOnIntersect';
import { useStateValue } from '@/state';
import { DesktopTopMenu } from '../DesktopTopMenu';
import { Footer } from '../Footer';

export interface IDesktopExtra {
  children: any;
  coverImage?: any;
  topSvg?: string;
  bottomSvg?: string;
  variant: number;
  location: string;
  heading: ReactNode;
  cta: ReactNode;
}

interface IDesktopExtraStyled {
  topSvg: string;
  bottomSvg: string;
  variant: number;
}

// eslint-disable-next-line no-shadow
enum Zindices {
  background = 1,
  shapes = 2,
  elements = 3,
  menu = 9999,
}

export const DesktopExtraStyled = styled.section<IDesktopExtraStyled>`
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.blue_1} 0%,
    ${({ theme }) => theme.colors.blue_1} 38.12%,
    ${({ theme }) => theme.colors.magenta_2} 88.95%,
    ${({ theme }) => theme.colors.magenta_2} 100%
  );
  flex: 1 0 auto;
  display: flex;
  position: relative;
  z-index: ${Zindices.background};
  flex-direction: column;
  height: auto;
  .layout-wide__menu {
    top: 0;
    position: fixed;
    display: flex;
    height: ${rem(`60px`)};
    width: 100%;
    justify-content: center;
    z-index: ${Zindices.menu};
    background: ${({ theme }) => theme.colors.blue_1_65};
    backdrop-filter: blur(8px);
  }
  .layout-wide__top {
    display: flex;
    overflow-x: hidden;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${({ variant }) => {
      switch (variant) {
        case 1:
          return rem(`1011px`);
        case 2:
          return rem(`1011px`);
        case 4:
          return rem(`701px`);
        default:
          return rem(`1011px`);
      }
    }};
    ${({ topSvg }) =>
      topSvg
        ? css`
            &::before,
            &::after {
              content: '';
              position: absolute;
              top: 0;
              width: calc(100vw - 50% - 960px);
              z-index: 0;
            }
            &::before {
              left: 0;
              background-color: ${({ theme }) => theme.colors.magenta_2};
              height: ${({ variant }) => {
                switch (variant) {
                  case 1:
                    return rem(`815px`);
                  case 2:
                    return rem(`533px`);
                  case 4:
                    return rem(`701px`);
                  default:
                    return rem(`366px`);
                }
              }};
            }
            &::after {
              right: 0;
              background-color: ${({ theme }) => theme.colors.magenta_1};
              height: ${({ variant }) => {
                switch (variant) {
                  case 1:
                    return rem(`815px`);
                  case 2:
                    return rem(`946px`);
                  case 4:
                    return rem(`617px`);
                  default:
                    return rem(`813px`);
                }
              }};
            }
            .center-shape {
              top: 0;
              height: 100%;
              background-image: linear-gradient(
                90deg,
                ${({ theme }) => theme.colors.magenta_2} 51.04%,
                #42278e 62.16%,
                ${({ theme }) => theme.colors.magenta_1} 76.99%,
                ${({ theme }) => theme.colors.magenta_1} 80.21%
              );
              mask-image: url(${topSvg});
              position: absolute;
              display: flex;
              justify-content: center;
              z-index: ${Zindices.background};
              width: 1920px;
            }
          `
        : css``}
  }
  .wide-image {
    width: 100%;
  }
  .layout-wide__mid {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: ${rem(`624px`)};
    min-height: calc(100vh - (624px + 1010px));
  }
  .layout-wide__bottom {
    display: flex;
    overflow-x: hidden;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${rem(`624px`)};
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 624px;
      width: calc(100vw - 50% - 960px);
      z-index: 0;
    }
    &::before {
      left: 0;
      background-color: ${({ theme }) => theme.colors.blue_1};
    }
    &::after {
      right: 0;
      background-color: ${({ theme }) => theme.colors.magenta_2};
    }
    .center-shape {
      bottom: 0;
      height: 100%;
      background-image: linear-gradient(
        90deg,
        ${({ theme }) => theme.colors.blue_1},
        ${({ theme }) => theme.colors.magenta_2}
      );
      mask-image: url(${({ bottomSvg }) => bottomSvg});
      position: absolute;
      display: flex;
      justify-content: center;
      z-index: ${Zindices.background};
      width: 1920px;
    }
  }
  .top-container {
    top: 0;
    z-index: ${Zindices.shapes};
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: ${({ variant }) => {
      switch (variant) {
        case 1:
          return rem(`1011px`);
        case 2:
          return rem(`1011px`);
        case 4:
          return rem(`701px`);
        default:
          return rem(`1011px`);
      }
    }};
    margin-bottom: ${({ topSvg }) => (topSvg ? rem(`72px`) : 0)});
  }
  .mid-container {
    position: relative;
    width: 100%;
    flex: 1 0 auto;
    flex-wrap: wrap;
    max-width: ${rem(`1200px`)};
    display: flex;
    margin-bottom: ${rem(`72px`)};
    .body {
      padding-top: ${rem(`48px`)};
      width: 100%;
    }
  }
  .bottom-container {
    position: absolute;
    bottom: 0;
    z-index: ${Zindices.shapes};
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .heading {
    display: grid;
    width: ${rem(`1200px`)};
    grid-template-columns: 1fr 1fr;
    column-gap: ${rem(`20px`)};
    justify-content: space-around;
    padding-top: ${rem(`80px`)};
    .content {
      width: 100%;
      display: flex;
      padding-top: ${rem(`144px`)};
    }
    .image {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .gatsby-image {
      max-height: ${rem(`714px`)};
    }
  }
  .footer {
    display: grid;
    width: ${rem(`1200px`)};
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: ${rem(`20px`)};
    justify-content: space-around;
    padding-bottom: ${rem(`160px`)};
    .content {
      width: 100%;
      display: flex;
      align-items: center;
      grid-column-start: 1;
      grid-column-end: 4;
      margin: 0;
    }
    .image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column-start: 4;
    }
  }
  .copy-right {
    position: absolute;
    bottom: 0;
    display: flex;
    height: ${rem(`72px`)};
    width: 100%;
    justify-content: center;
    z-index: ${Zindices.menu};
    background: ${({ theme }) => theme.colors.blue_1_30};
  }
  .continue-reading {
    margin-top: ${rem(`120px`)};
    justify-content: flex-end;
    svg {
      animation: scale 2s ease-in-out infinite;
      path {
        fill: url('#rainbow_1_svg') #fff;
      }
    }
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const DesktopExtra = ({
  children,
  coverImage,
  topSvg,
  bottomSvg,
  variant,
  location = `/`,
  heading,
  cta,
}: IDesktopExtra) => {
  const [, dispatch] = useStateValue();
  const { logoBig } = useStaticQuery(graphql`
    query LogoBigQuery {
      logoBig: contentfulAsset(
        contentful_id: { eq: "3d6RRjX4QkuDsKlbP6Mejy" }
      ) {
        svg {
          dataURI
        }
      }
    }
  `);
  const logoImageBig = logoBig?.svg?.dataURI;
  const topRef = useRef();

  useActiveOnIntersect(
    () =>
      dispatch({
        type: `changeLocation`,
        newLocationState: { current: `#top` },
      }),
    topRef,
  );

  return (
    <DesktopExtraStyled
      topSvg={topSvg && `data:image/svg+xml,${encodeURIComponent(topSvg)}`}
      bottomSvg={`data:image/svg+xml,${encodeURIComponent(bottomSvg)}`}
      variant={variant}
      id="intersector"
      className="intersection-line"
    >
      <>
        <div className="layout-wide__menu">
          <DesktopTopMenu location={location} />
        </div>
        <div className="layout-wide__top" id="top">
          {variant === 4 && coverImage && (
            <GatsbyImage alt="123" image={coverImage} className="wide-image" />
          )}
          {topSvg && <div className="center-shape" ref={topRef} />}
        </div>
        <div className="top-container">
          <div className="heading">
            <div className="content">{heading}</div>
            {variant !== 4 && coverImage && (
              <div className="image">
                <GatsbyImage
                  alt="123"
                  image={coverImage}
                  className="gatsby-image"
                />
              </div>
            )}
          </div>
        </div>
        <div className="layout-wide__mid">
          <svg
            style={{ width: 0, height: 0, position: `absolute` }}
            aria-hidden="true"
            focusable="false"
          >
            <linearGradient
              id="rainbow_1_svg"
              x1="50%"
              y1="60%"
              gradientTransform="rotate(165)"
            >
              <stop offset="0%" stopColor="#FEB707" />
              <stop offset="25%" stopColor="#FEB707" />
              <stop offset="60" stopColor="#F638FB" />
              <stop offset="100%" stopColor="#F638FB" />
            </linearGradient>
          </svg>
          <div className="mid-container">{children}</div>
        </div>
        <div className="bottom-container">
          <div className="footer">
            <div className="content">{cta}</div>
            <div className="image">
              <img
                alt="footer logo"
                width="164"
                height="164"
                src={logoImageBig}
              />
            </div>
          </div>
        </div>
        <div className="layout-wide__bottom">
          <div className="center-shape" />
        </div>
        <div className="copy-right">
          <Footer showLogo={false} disableBackground />
        </div>
      </>
    </DesktopExtraStyled>
  );
};
