import styled from 'styled-components';
import { rem } from 'polished';
import { StyledText } from '../Text/styles';

interface IStyledContent {
  restrictWidth: any;
  shouldCenter: any;
  enableFlex: any;
  setMaxWidth: any;
  disableTextStyles: any;
  disableSubtitlePadding: any;
  contrast: any;
  noMargin: boolean;
}

interface IStyledContentHtml {
  restrictWidth: any;
  dangerouslySetInnerHTML: any;
  className: any;
}

function isDisableTextStylesThen(size: string) {
  return ({ disableTextStyles }) => (disableTextStyles ? 0 : size);
}

function isDisableTextOrSubtitlePaddingThen(size: string) {
  return function props({ disableTextStyles, disableSubtitlePadding }) {
    return disableTextStyles || disableSubtitlePadding ? 0 : size;
  };
}

function setFontSize(fontName, fontSizeRem, lineHeight) {
  return `
    font-family: ${fontName};
    font-size: ${fontSizeRem}rem;
    line-height: ${lineHeight}rem;
  `;
}

export const StyledContent = styled.div<IStyledContent>`
  flex: 1 0;
  text-align: ${({ shouldCenter }) => (shouldCenter ? `center` : `left`)};
  align-items: ${({ shouldCenter }) =>
    shouldCenter ? `center` : `flex-start`};
  display: ${({ enableFlex }) => (enableFlex ? `flex` : `block`)};
  color: ${({ theme, contrast }) => {
    if (contrast) {
      return theme.colors.blue_1_65;
    }
    return theme.colors.white_1_45;
  }};
  ${({ theme }) => theme.breakpoints.desktop`
    max-width: ${({ setMaxWidth }) => setMaxWidth || `100%`};
  `}
  margin-bottom: ${({ noMargin }) => (noMargin ? `0` : rem(`48px`))};
  &.last {
    margin-bottom: ${rem(`72px`)};
  }
  .title1 {
    margin-bottom: ${isDisableTextStylesThen(rem(`32px`))};
  }
  .title3 {
    margin-bottom: ${isDisableTextStylesThen(rem(`6px`))};
    ${({ theme }) => theme.breakpoints.phablet`
      margin-bottom: 0;
    `}
  }
  .subtitle1 {
    margin-bottom: ${isDisableTextOrSubtitlePaddingThen(rem(`8px`))};
  }
  .body1 {
    margin-bottom: ${isDisableTextStylesThen(rem(`12px`))};
    ${({ theme, disableTextStyles }) => theme.breakpoints.phablet`
      margin-bottom: ${disableTextStyles ? 0 : rem(`24px`)};
    `}
    p, ul {
      margin-bottom: ${isDisableTextStylesThen(rem(`12px`))};
      &:last-child {
        margin-bottom: 0;
      }
      ${({ theme, disableTextStyles }) => theme.breakpoints.phablet`
        margin-bottom: ${disableTextStyles ? 0 : rem(`24px`)};
      `}
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledContentHtml = styled(StyledText)<IStyledContentHtml>`
  flex: 1 0;
  width: 100%;
  margin-bottom: ${rem(`72px`)};
  color: ${({ theme }) => theme.colors.gray_3};
  ${({ theme, restrictWidth }) => theme.breakpoints.wide`
    max-width: ${restrictWidth ? `75%` : `100%`};
  `}
  &.content--html {
    .title3 {
      margin-top: 72px;
    }
    .subtitle1 {
      margin-top: 32px;
      color: ${({ theme }) => theme.colors.white_1_45};
    }
    .body1 {
      margin-top: 16px;
    }
  }
  ul {
    flex: 1 0;
    margin: ${rem(`12px`)} 0;
  }
  li {
    flex: 1 0;
    font-weight: 400;
    margin-bottom: ${rem(`2px`)};
    ${setFontSize(`Titillium Web`, 0.875, 1)}
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 1, 1.25)}
    `}
  }
  p {
    font-weight: 400;
    ${setFontSize(`Titillium Web`, 0.875, 1)}
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 1, 1.5)}
    `}
  }
  p + h1 {
    margin-top: ${rem(48)};
  }
  figure + h1,
  figure + p + h1 {
    margin-top: 0;
  }
  h1 {
    font-weight: 700;
    margin-bottom: ${rem(12)};
    ${setFontSize(`Titillium Web`, 2.25, 2.25)}
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 2.5, 3)}
    `};
  }
  h2 {
    font-weight: 700;
    padding-top: ${rem(24)};
    margin-bottom: ${rem(6)};
    ${setFontSize(`Titillium Web`, 1.75, 1.75)}
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 2, 2)}
    `}
  }
  h3 {
    font-weight: 500;
    padding-top: ${rem(24)};
    margin-bottom: ${rem(6)};
    color: ${({ theme }) => theme.colors.gray_4};
    ${setFontSize(`Titillium Web`, 1.625, 1.625)}
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 1.75, 1.75)}
    `}
  }
  h4 {
    font-weight: 300;
    padding-top: ${rem(12)};
    margin-bottom: ${rem(4)};
    ${setFontSize(`Titillium Web`, 1.25, 1.25)}
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 1.5, 1.5)}
    `}
  }
  h5 {
    font-weight: 300;
    padding-top: ${rem(12)};
    margin-bottom: ${rem(4)};
    ${setFontSize(`Titillium Web`, 1.125, 1.125)};
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 1.125, 1.125)}
    `};
  }
  h6 {
    font-weight: 300;
    padding-top: ${rem(12)};
    margin-bottom: ${rem(4)};
    ${setFontSize(`Titillium Web`, 1, 1)}
    ${({ theme }) => theme.breakpoints.phablet`
      ${setFontSize(`Titillium Web`, 1.125, 1)}
    `}
  }
  blockquote {
    position: relative;
    font-weight: 300;
    margin: ${rem(24)} 0 ${rem(24)} ${rem(24)};
    padding: ${rem(24)} ${rem(24)} ${rem(24)} ${rem(48)};
    color: ${({ theme }) => theme.colors.gray_4};
    background-color: ${({ theme }) => theme.colors.magenta_2_00};
    &:before {
      content: 'NOTE: ';
      display: inline;
      color: ${({ theme }) => theme.colors.yellow_1};
    }
    &:after {
      content: '';
      width: 2px;
      height: 100%;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      background-color: ${({ theme }) => theme.colors.yellow_1};
    }
    ${setFontSize(`Titillium Web`, 1.33, 1.5)}
    > p {
      display: inline;
      ${setFontSize(`Titillium Web`, 1.33, 1.5)}
    }
  }

  code {
    font-family: monospace;
    font-size: 13px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.blue_4};
    background-color: ${({ theme }) => theme.colors.white_1_45};
    padding: 1px 4px;
    border-radius: 6px;
    margin: 0 3px;
    word-break: keep-all;
  }

  .gatsby-resp-image-figure {
    margin: ${rem(`24px`)} 0;
  }

  .gatsby-resp-image-figcaption {
    margin-top: ${rem(`6px`)};
    text-align: center;
    color: ${({ theme }) => theme.colors.gray_3_65};
    ${setFontSize(`Titillium Web`, 0.75, 1.125)}
  }

  .gatsby-resp-image-link {
    display: flex;
    flex: 0 1 auto;
    position: relative;
    z-index: 1;
    border-radius: ${rem(`8px`)};
    overflow: hidden;
  }

  .gatsby-resp-image-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    color: ${({ theme }) => theme.colors.white_1};
    border-radius: ${rem(`8px`)};
    max-width: initial !important;
  }

  .gatsby-resp-image-background-image {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    padding: 1px;
    margin: 1px;
    border-radius: ${rem(`8px`)};
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    border: solid 1px transparent;
    background: ${({ theme }) => `linear-gradient(
      265deg,
      ${theme.colors.blue_1},
      ${theme.colors.blue_1_90}
    )`};
    ::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: inherit;
      margin: -2px;
      z-index: -1;
      background: ${({ theme }) => `linear-gradient(
          295deg,
          ${theme.colors.blue_1_30},
          ${theme.colors.blue_1_65},
          ${theme.colors.pink_2}
        )`};
    }
  }

  .gatsby-resp-image-image {
    border-radius: ${rem(`8px`)};
    flex: 1 0 auto;
    position: relative;
    z-index: 1010;
    box-shadow: none !important;
  }

  img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }

  a.autolink-headers {
    fill: ${({ theme }) => theme.colors.white_1};
  }
`;
