import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledScrollIndicator {
  isMenuCollapsed: any;
}

export const StyledScrollIndicator = styled.div<IStyledScrollIndicator>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 16px;
  position: fixed;
  bottom: 0;
  width: calc(
    100% - ${({ isMenuCollapsed }) => (isMenuCollapsed ? 620 : 776)}px
  );
  height: ${({ theme }) => theme.rhythmSizing(10)}rem;
  transition: width 300ms ease-in-out;
  background-image: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.blue_1},
    ${({ theme }) => theme.colors.blue_1_00}
  );
  z-index: 1199;
  pointer-events: none;
  .scrollIndicator__arrow {
    width: ${rem(`100px`)};
    height: ${rem(`48px`)};
    pointer-events: all;
    &--down {
      animation: Scale 2800ms ease-in-out infinite;
      svg {
        animation: Aura 2800ms ease-in-out infinite;
        color: ${({ theme }) => theme.colors.white_1};
      }
    }
  }

  @keyframes Scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes Aura {
    0% {
      color: ${({ theme }) => theme.colors.white_1};
    }
    50% {
      color: ${({ theme }) => theme.colors.pink_1};
    }
    100% {
      color: ${({ theme }) => theme.colors.white_1};
    }
  }
`;
